.weather-chart-container {
    height: 500px;
    width: 500px;
    @media screen and (max-width: 767px) {
        height: 400px;
      }
    
      @media screen and (max-width: 480px) {
        height: 400px;
      }
}