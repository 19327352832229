@import "styles";

.footer-container {
    width: 100%;
    background-color: $black;

    .text {
        color: $white;
        padding: 16px;
        text-align: center;
        .contact-us {
            font-size: 20px;
            color: $color-1
        }
    }

    .footer-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        a {
            color: $white;
        }
        
    }
}