@import "styles";

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
}

.apartment-container {
  padding: 20px 50px;

  @media screen and (max-width: 768px) {
    padding: 20px 15px;
  }

  .back-button {
    display: flex;
    max-width: 100px;
    font-weight: $font-weight-600;
    cursor: pointer;

    &:hover {
      color: $color-2;
      font-weight: $font-weight-600;
    }
  }

  .swipers-container {
    background: $color-3;

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    body {
      background: $black;
      color: $black;
    }

    .swiper {
      padding-top: 10px;
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-slide {
      background-size: cover;
      background-position: center;
    }

    .mySwiper2 {
      height: 80%;
      width: 50%;

      @media screen and (max-width: 1025px) {
        width: 80%;
      }

      .swiper-button-prev,
      .swiper-button-next {
        &:hover {
          // box-shadow: 0px 0px 16px rgba(4,4,4,.1);
          color: $color-1;
        }
      }

      .swiper-pagination-bullet {
        background: $color-5;
      }
      .swiper-pagination-bullet-active {
        background: $color-1;
      }
    }

    .mySwiper {
      height: 100px;
      box-sizing: border-box;
      padding: 10px 5px;

      .swiper-button-prev,
      .swiper-button-next {
        --swiper-navigation-size: 22px;

        &:hover {
          box-shadow: 0px 0px 16px rgba(4, 4, 4, 0.1);
          color: $color-1;
        }
      }
    }

    .mySwiper .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
      border-radius: 11px;

      img {
        border-radius: 10px;
      }
    }

    .mySwiper .swiper-slide-thumb-active {
      opacity: 1;
      border: 2px solid $color-1;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .apartment-text-container {
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $grey;
    padding: 30px;

    @media screen and (max-width: 768px) {
      padding: 30px 30px 0 30px;
    }
    @media screen and (max-width: 480px) {
      padding: 30px 30px 0 30px;
    }

    .apartment-header-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 768px) {
        gap: 10px;
        flex-direction: column;
      }
  
      @media screen and (max-width: 480px) {
        gap: 10px;
        flex-direction: column;
      }

      .apartment-name {
        font-weight: $font-weight-600;
        font-size: 20px;
      }

      .airbnb-button {
        width: 200px;
        height: 30px;
        // border: 1px solid $color-1;
        border-radius: 90px;
        background-color: $color-1;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse 1s infinite;

        &:hover {
          background-color: $black;
          cursor: pointer;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          text-decoration: none;
          font-size: 16px;
          font-weight: $font-weight-600;
          color: $black;

          &:hover {
            color: $color-1;
          }
        }
      }
    }

    .apartment-furnishings-name {
      font-weight: $font-weight-600;
      font-size: 18px;
      margin-top: 20px;
    }

    .apartment-basic {
      display: flex;

      .basic-container {
        display: flex;
        align-items: center;
        margin-right: 20px;

        p {
          display: flex;
          margin-left: 5px;
        }
        
        @media screen and (max-width: 1024px) {
          margin-right: 10px;
        }

        @media screen and (max-width: 768px) {
          margin-right: 10px;
        }

        @media screen and (max-width: 480px) {
          fmargin-right: 10px;
        }
      }
    }

    .apartment-furnishings-map {
      display: flex;
      width: 100%;
      align-items: center;
      
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 20px;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: 20px;
        .map{
          height: 300px !important;
          padding-bottom: 10px;
        }
      }
    }

    .apartment-furnishings {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .basic-container {
        display: flex;
        align-items: center;

        span {
          margin-right: 5px;
        }

        .text {
          text-align: left;
        }
      }
    }

    .apartment-description {
      font-size: 16px;
      text-align: left;

      .apartment-description-container {
        display: flex;
        gap: 20px;
        flex-direction: column;

        .description-header-text {
          font-weight: 600;
          font-size: 18px;;
        }
      }
    }
  }
}

.apartment-reservation-container {
  padding-top: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: start;
  width: 100%;

  .reservation-header-text {
    font-weight: 600;
    font-size: 18px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      gap: 10px;
      flex-direction: column;
    }

    @media screen and (max-width: 480px) {
      gap: 10px;
      flex-direction: column;
    }
    
    .airbnb-button {
      width: 200px;
      height: 30px;
      // border: 1px solid $color-1;
      border-radius: 90px;
      background-color: $color-1;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: pulse 1s infinite;

      &:hover {
        background-color: $black;
        cursor: pointer;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        font-size: 16px;
        font-weight: $font-weight-600;
        color: $black;

        &:hover {
          color: $color-1;
        }
      }
    }
  }

  .reservation-description {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    @media screen and (max-width: 768px) {
      gap: 0;
    }

    @media screen and (max-width: 480px) {
      gap: 0;
    }
    

    .text {
      margin: 0px;
    }
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  width: 90%;
  height: 90%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.popupSwiper {
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
  z-index: 1100;
}

.popupSwiper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
