@import "styles";
.sightseeing-info-container {
  padding: 20px;
}

.sightseeing-container {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .selected-place {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img, p {
        // width: 100%;
        max-width: 600px;
        height: auto;
        display: block;
        margin: 0 auto;
        max-height: 500px;  
    }

    .description {
        padding: 20px 0;
    }
  }
}
