@import "styles";

.header-container {
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-container {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .logo {
            img {
                max-width: 100px;
                height: auto;
                @media screen and (max-width: 768px) {
                    max-width: 150px;
                    height: auto;
                }
                @media screen and (max-width: 480px) {
                    max-width: 70px;
                    height: auto;
                }
            }
        }

        .logo_title {
            img {
                @media screen and (max-width: 768px) {
                    max-width: 200px;
                }
                @media screen and (max-width: 480px) {
                    max-width: 200px;
                }
            }
        }

        .title {
            padding: 0 20px;
        }
    }

    .pink {
        color: $pink;
    }

    .golden {
        color: $golden;
        text-shadow: $shine;
    }
    .hamburger-menu {
        display: none;
    }

    .popup-menu {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        z-index: 1000;

                @media screen and (min-width: 480px) and (max-width: 768px) {
                    top: 150px;
                }
                @media screen and (max-width: 1040px) and (min-width: 769px) {
                    top: 99px;
                }
        .navigation,
        .languages {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $white;
            cursor: pointer;

            .language-option {
                display: flex;
                flex-direction: column;

                .flag {
                    svg {
                        width: 30px;
                        height: 20px;
                    }
                }
            }

            .flags-container {
                display: flex;
            }
        }

        .languages {
            justify-content: center;
            cursor: pointer;
            flex-direction: column;

            .language-option {
                display: flex;
                flex-direction: column;

                .flag {
                    svg {
                        width: 30px;
                        height: 20px;
                    }
                }
            }
            .menu-separator {
                background-color: $white;
                height: 1px;
                width: 80%;
            }

            .flags-container {
                display: flex;
                gap: 10px;
            }
        }

        .navigation {
            flex-direction: column;
        }
    }

    .navigation {
        gap: 20px !important;
        font-size: 21px;
    }

    .navigation,
    .languages {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $white;
        margin-right: 10px;
        cursor: pointer;

        div {
            &:hover {
                color: $color-2;
                font-weight: $font-weight-600;
            }
        }

        .language-option {
            display: flex;
            flex-direction: column;

            .flag {
                svg {
                    width: 30px;
                    height: 20px;
                }
            }
        }

        .language-option.selected {
            color: $color-5;
        }

    }

    @media screen and (max-width: 1040px) {
        .logo-container {
            cursor: pointer;
            padding: 0;
        }
        
        .hamburger-menu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 10px;

            .line {
                width: 30px;
                height: 3px;
                background-color: white;
                margin: 3px 0;
                transition: all 0.3s ease;
            }

            &.open .line:first-child {
                transform: rotate(45deg) translate(5px, 5px);
            }

            &.open .line:nth-child(2) {
                opacity: 0;
            }

            &.open .line:last-child {
                transform: rotate(-45deg) translate(5px, -5px);
            }
        }

        .navigation,
        .languages {
            display: none;
            width: 100%;
            padding: 10px 0;
            background-color: $black;

            .language-option {
                display: flex;
                flex-direction: column;

                .flag {
                    svg {
                        width: 30px;
                        height: 20px;
                    }
                }
            }
        }

        &.open .navigation,
        &.open .languages {
            display: flex;
            .language-option {
                display: flex;
                flex-direction: column;

                .flag {
                    svg {
                        width: 30px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

