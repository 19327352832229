.home-info-text {
    display: flex;
    justify-content: center;

    .center {
        width: 500px;
        margin: 10px;
        font-size: 16px;
    }
}

.home-city-info-text {
    display: flex;
    justify-content: center;

    .center {
        width: 700px;
        margin: 10px;
        font-size: 16px;
    }
}
.separator {
    padding: 10px 0;
}