.island-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 25px;

    .island-info{
        font-weight: 600;
    }
}

