.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-cookies-content {
  position: absolute;
  bottom: 50px;
  width: 90%;
  height: auto;
  padding: 0 10px 30px 10px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    position: relative;
    padding: 0 10px 30px 10px;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    position: relative;
    padding: 0 10px 30px 10px;
    height: auto;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    padding: 0 10px 30px 10px;
    height: auto;
  }

}

button {
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.popup-ul {
  background-color: #fff;
  text-align: left;
}
.popup-buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 110px;


    .cookies-accept-button {
        background-color: green;
        &:hover {
          background-color: rgb(0, 99, 0);
        }
    }
}

.close-button-wrapper {
  display: flex;
  justify-content: end;

  .cookies-close-button {
    color: red;
    background-color: white;
    border: 1px solid red;
    margin-top: 10px;
    padding: 2px 5px;

    &:hover {
      color: white;
      background-color: red;
    }
  }
}

