 @import './styles/variables.scss';

.App {
  text-align: center;

  .content {
    background: $color-4;

    .title-app {
      font-size: 32px;
      font-weight: bold;
      padding: 20px 0;
    }
  } 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-home {
  background-color: $color-4;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    .card {
      .image {
        max-width: 350px !important;
      }
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    .card {
      .image {
        max-width: 350px !important;
      }
    }
  }

  .card {
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 0px 16px rgba(4,4,4,.1);
    border-radius: 15px;
    margin: 10px;

    &:hover {
      box-shadow: 0px 0px 48px rgba(4, 4, 4, 0.25);
    }
    .image{
          max-width: 500px;
          border-radius: 15px 15px 0 0;;
    }

    .title {
      display: flex;
      font-size: 24px;
      color: $black;
      padding: 10px;
      justify-content: center;

      &:hover {
        color: $color-1;
      }
    }
  };
}